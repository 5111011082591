.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loader-dots div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader-dots div:nth-child(1) {
  left: 2px;
  animation: loader-dots1 0.6s infinite;
}

.loader-dots div:nth-child(2) {
  left: 2px;
  animation: loader-dots2 0.6s infinite;
}

.loader-dots div:nth-child(3) {
  left: 26px;
  animation: loader-dots2 0.6s infinite;
}

.loader-dots div:nth-child(4) {
  left: 50px;
  animation: loader-dots3 0.6s infinite;
}

@keyframes loader-dots1 {
  0% {
      transform: scale(0);
  }

  100% {
      transform: scale(1);
  }
}

@keyframes loader-dots3 {
  0% {
      transform: scale(1);
  }

  100% {
      transform: scale(0);
  }
}

@keyframes loader-dots2 {
  0% {
      transform: translate(0, 0);
  }

  100% {
      transform: translate(24px, 0);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* aligns antd icons properly in buttons.*/
svg {
  vertical-align: baseline;
}
